import * as React from 'react';
import { Rating } from './components/rating';
import { v4 } from 'uuid';

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      rating: 1,
      notes: '',
      food: '',
      helpers: '',
      loading: false
    };
  }

  setRating(rating) {
    this.setState({ rating });
  }

  setNotes(event) {
    this.setState({ notes: event.target.value });
  }

  setFood(event) {
    this.setState({ food: event.target.value });
  }

  setHelpers(event) {
    this.setState({ helpers: event.target.value });
  }

  async submit() {
    this.setState({ loading: true });
    const { food, helpers } = this.state
    await fetch(`https://bsffy6hls1.execute-api.us-east-1.amazonaws.com/anxiety`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: v4(), rating: Number(this.state.rating), note: this.state.notes, food, helpers })
    });
    this.setState({ rating: '', notes: '', food: '', loading: false, helpers: ''});
  }

  render() {
    return (
      <div className="container">
        <div className="loader" style={{ display: `${this.state.loading === true ? 'block' : 'none'}`}}>
          <div class="spinner-border spinner-center" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
          <div className="row top-row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <h3>Anxiety</h3>
                <Rating onChange={this.setRating.bind(this)} rating={this.state.rating} />
              </div>
              <div className="col-md-4"></div>
          </div>
          <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <h3>Notes</h3>
                <textarea onChange={this.setNotes.bind(this)} value={this.state.notes} className="form-control"></textarea>
              </div>
              <div className="col-md-4"></div>
          </div>
          <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <h3>Food</h3>
                <textarea  onChange={this.setFood.bind(this)} value={this.state.food} className="form-control"></textarea>
              </div>
              <div className="col-md-4"></div>
          </div>
          <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <h3>What Helped?</h3>
                <textarea onChange={this.setHelpers.bind(this)} value={this.state.helpers} className="form-control"></textarea>
              </div>
              <div className="col-md-4"></div>
          </div>
          <div className="row">
            <div className="col-4"></div>
            <div className="col-4">
              <button onClick={this.submit.bind(this)} className="form-control btn btn-success">Submit</button>
            </div>
            <div className="col-4"></div>
          </div>
      </div>
    );
  }
}

export default App;
